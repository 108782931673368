span.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background-color: #2d2964;
}

#clients .swiper-wrapper {
  display: flex;
  align-items: center;
}

#clients .swiper-slide {
  display: flex;
  justify-content: center;
}

#gallerySlider .swiperProjects {
  margin: 100px auto;
  width: 300px;
}
#gallerySlider img {
  width: 100%;
}

@media screen and (max-width: 520px) {
  #gallerySlider .swiperProjects {
    margin: 50px auto;
    width: 230px;
  }
}

@media screen and (min-width: 768px) {
  #gallerySlider .swiperProjects {
    margin: 50px auto;
    width: 500px;
  }
}