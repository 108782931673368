body {
  margin: 0;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  color: #313032
}

section h2.h2After::after {
  content: "";
  display: block;
  width: 100px;
  height: 5px;
  background: #2D2964;
  margin-top: 20px;
  margin-bottom: 20px
}
section h2.marginAfterAuto::after {
  margin-left: auto;
  margin-right: auto;
}
section h2.h2BeforePrimary::before {
  content: "";
  display: block;
  width: 100px;
  height: 5px;
  background: #2D2964;
  margin-top: 20px;
  margin-bottom: 20px
}

section#contact h2 {
  font-weight: 700;
  font-size: 25px;
  line-height: 29px;
}

section#contact ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

section#contact ul li svg {
  color: #2D2964
}

section#contact ul li a {
  text-decoration: none;
  color: #313032;
}

section#contact ul li a:hover {
  text-decoration: underline;
  color: #2D2964;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}

.gridDetailsProject {
  padding: 20px;
  background: #EEEEEE;
  border: 1px solid #E0E0E0;
  border-radius: 10px;
  position: static;
  top: 15px;
}

.gridGalleryProject {
  background-color: #2D2964;
  padding: 50px 0;
  color: #EEEEEE;
}

h2.h2Before::before {
  content: "";
  display: block;
  width: 50px;
  height: 5px;
  background: #EEEEEE;
  margin-bottom: 20px;
  bottom: -75px;
  position: relative;
}
