.nav-link {
  color: #2d2964 !important;
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  text-transform: uppercase;
}
.active {
  color: #d4192a !important;
}
