.bigHero {
  background-image: url("../../../public/assets/images/img-bg-hero-home-fwc.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  min-height: 600px;
}
.bigHero__title {
  font-weight: 700;
  font-size: 50px;
  letter-spacing: -0.022em;
  margin-bottom: 50px;
}
.bigHero__text {
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
  letter-spacing: -0.022em;
}

.buttonTheme {
  border-radius: 5px;
  border: none;
  padding: 10px 15px;
  text-transform: uppercase;
  font-weight: 700;
  font-size: 15px;
  transition: all 0.1s ease-in-out;
}

.buttonThemeSolid {
  box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.5);
}
.buttonThemeSolid:hover {
  opacity: 0.8;
}
.buttonThemeSolid:active {
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.3);
}

.buttonThemeOutline {
  border-style: solid !important;
  border-width: 2px !important;
  background-color: transparent !important;
  padding: 8px 15px;
}
.buttonThemeOutline:active {
  background-color: rgba(255, 255, 255, 0.1) !important;
}
.buttonThemeSolid:disabled,
.buttonThemeSolid[disabled] {
  border: 1px solid #999999;
  background-color: #cccccc !important;
  color: #666666;
  box-shadow: none;
  cursor: no-drop;
}
.buttonThemeSolid:disabled:hover {
  opacity: 1;
}
.containerFeatures {
  padding: 50px;
  background: linear-gradient(91.59deg, #f6f5ff 0.11%, #eeeeee 100%);
  box-shadow: 0px 4px 26px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  margin-top: -100px;
}

.containerFeatures__titleFeatureHorizontal {
  font-weight: 700;
  font-size: 15px;
  line-height: 18px;
  text-align: center;
  text-transform: uppercase;
}

section h2.h2After::after {
  content: "";
  display: block;
  width: 100px;
  height: 5px;
  background: #2d2964;
  margin-top: 20px;
  margin-bottom: 20px;
}
section h2.marginAfterAuto::after {
  margin-left: auto;
  margin-right: auto;
}

section h2.h2Before::before {
  content: "";
  display: block;
  width: 100px;
  height: 5px;
  background: #eeeeee;
  margin-top: 20px;
  margin-bottom: 20px;
}
section h2.h2BeforePrimary::before {
  content: "";
  display: block;
  width: 100px;
  height: 5px;
  background: #2d2964;
  margin-top: 20px;
  margin-bottom: 20px;
}
.memberTeam {
  transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  min-height: 382px;
}
.memberTeam:hover {
  transform: translateY(-10px);
}
.memberTeam h5 {
  text-transform: uppercase;
}

.cardCategory {
  width: 100%;
  background-color: #eeeeee;
  padding: 20px;
  border-radius: 10px;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
}
.cardCategory:hover,
.cardCategory.selected {
  background-color: #2d2964;
  color: #eeeeee;
}
.cardCategoryProject {
  background-color: #2d2964;
  padding: 10px;
  border-radius: 10px;
  color: #eeeeee;
}
.cardCompletedProject {
  background-color: #7f7f7f;
  padding: 10px;
  border-radius: 10px;
  color: #eeeeee;
}
.cardProject {
  padding: 20px;
  background: #eeeeee;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.15);
  border-radius: 10px;
  transition: all 0.2s ease-in-out;
  min-height: 250px;
  margin-bottom: 15px;
}
.cardProjectImage {
  width: 100%;
  min-height: 250px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.cardProject:hover {
  box-shadow: 0px 10px 15px rgba(0, 0, 0, 0.2);
}
.cardProjectTitle {
  font-weight: 700;
  font-size: 30px;
  line-height: 35px;
}
